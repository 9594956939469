<template>
        <fr-list-item ref="usfModal" :collapsible="true" :panelShown="false"  @show="showCancelButton = true;" @keydown.enter.native.prevent="saveForm" @hide="showCancelButton = true; clearComponent()">>
            <div slot="list-item-header" class="d-inline-flex w-100 media">
                <div class="media-body align-self-center">
                    <h6 class="mt-2">{{$t('pages.profile.mfaDevices.title')}}</h6>
                </div>
            </div>
            <div slot="list-item-collapse-body" class="d-inline-flex w-100">
                <b-form class="w-100">
                    <b-row>
                        <b-col sm="8">
                            <fr-loading-button type="button" variant="primary" class="ld-ext-right mb-3"
                                :label="$t('pages.profile.mfaDevices.button')"
                                :loading="loading"
                                @click="saveForm()">
                            </fr-loading-button>
                        </b-col>
                    </b-row>
                </b-form>
            </div>
        </fr-list-item>
</template>
<script>
import ListItem from '@/components/utils/ListItem';
import LoadingButton from '@/components/utils/LoadingButton';
import noop from 'lodash';
import ResourceMixin from '@/components/utils/mixins/ResourceMixin';

export default {
    name: 'manage-MFA',
    components: {
        'fr-list-item': ListItem,
        'fr-loading-button': LoadingButton
    },
    mixins: [
        ResourceMixin
    ],
    data () {
        return {
            formData: {},
            relaationshipProperties: {},
            formFields: [],
            originalFormFields: [],
            clearMFAIndex: 0,
            loading: false,
            showCancelButton: false
        };
    },
    methods: {
        clearComponent () {
            this.clearMFAIndex = 0;
            this.loading = false;
            this.showCancelButton = false;
        },
        saveForm () {
            const headers = {
                    'X-Requested-With': 'XMLHttpRequest'
                },
                payload = [{ operation: 'add', field: '/clearOATH', value: true }],
                onSuccess = this.clearComponent();
            this.$emit('updateProfile', payload, { headers, onSuccess, noop });
        }
    }
};
</script>
